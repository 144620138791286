import React from 'react';

function InvoiceSettings({ currency, language, handleCurrencyChange, handleLanguageChange }) {
  return (
    <section className="container mx-auto my-8 p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Invoice Settings</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Currency</label>
          <select
            value={currency}
            onChange={handleCurrencyChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          >
            <option value="USD">USD - US Dollar</option>
            <option value="EUR">EUR - Euro</option>
            <option value="GBP">GBP - British Pound</option>
            <option value="JPY">JPY - Japanese Yen</option>
            {/* Add more currencies as needed */}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Language</label>
          <select
            value={language}
            onChange={handleLanguageChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          >
            <option value="en">English</option>
            <option value="es">Español</option>
            {/* Add more languages as needed */}
          </select>
        </div>
      </div>
    </section>
  );
}

export default InvoiceSettings;
