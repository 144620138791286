import React from "react";

function InvoiceInformation({
  invoiceNumber,
  invoiceDate,
  expirationDate,
  observations,
  terms,
  handleInputChange,
}) {
  return (
    <section className="container mx-auto my-8 p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Invoice Information</h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        {/* Invoice Number */}
        <div>
          <label className="block text-sm font-medium text-gray-700 pt-4">
            Invoice Number
          </label>
          <input
            type="text"
            name="invoiceNumber"
            value={invoiceNumber}
            onChange={handleInputChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
            placeholder="INV-0001"
            maxLength={15} // Set maximum length to 10 characters
          />
        </div>

        {/* Invoice Date */}
        <div>
          <label className="block text-sm font-medium text-gray-700 pt-4">
            Invoice Date
          </label>
          <input
            type="date"
            name="invoiceDate"
            value={invoiceDate}
            onChange={handleInputChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          />
        </div>

        {/* Expiration Date */}
        <div>
          <label className="block text-sm font-medium text-gray-700 pt-4">
            Expiration Date
          </label>
          <input
            type="date"
            name="expirationDate"
            value={expirationDate}
            onChange={handleInputChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Observations */}
        <div>
          <label className="block text-sm font-medium text-gray-700 pt-4">
            Observations
          </label>
          <textarea
            name="observations"
            value={observations}
            onChange={handleInputChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
            placeholder="Additional observations or notes."
            rows="4"
          />
        </div>

        {/* Terms */}
        <div>
          <label className="block text-sm font-medium text-gray-700 pt-4">
            Terms and Conditions
          </label>
          <textarea
            name="terms"
            value={terms}
            onChange={handleInputChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
            placeholder="Payment terms or other conditions."
            rows="4"
          />
        </div>
      </div>
    </section>
  );
}

export default InvoiceInformation;
