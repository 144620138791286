import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

// You will get these values from the Auth0 dashboard
const domain = "dev-smeizfbhqd5tvdks.eu.auth0.com"; // e.g., dev-abc1234.us.auth0.com
const clientId = "rhmsJz7KEgGm9zyLIdUYiSbZv3QPTmxp"; // e.g., a1b2c3d4E5F6g7H8I9j0klmnop

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <App />
  </Auth0Provider>,
);

