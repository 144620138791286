import React from 'react';

const DownloadLimitAlert = ({ message }) => {
  return (
    <div className="alert-container bg-red-500 text-white p-4 rounded-lg shadow-md mb-4 text-center">
      <p className="font-semibold text-lg">{message}</p>
    </div>
  );
};

export default DownloadLimitAlert;
