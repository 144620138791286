import React from 'react';

function BusinessInfo({ businessInfo, handleBusinessInfoChange }) {
  return (
    <section className="p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Your Business Information</h2>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Business Name</label>
        <input
          type="text"
          name="businessName"
          value={businessInfo.businessName}
          onChange={handleBusinessInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="My Business Inc."
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Business Address</label>
        <input
          type="text"
          name="businessAddress"
          value={businessInfo.businessAddress}
          onChange={handleBusinessInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="456 Business St"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4 pt-4">Business Email</label>
        <input
          type="email"
          name="businessEmail"
          value={businessInfo.businessEmail}
          onChange={handleBusinessInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="business@example.com"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Business Phone</label>
        <input
          type="text"
          name="businessPhone"
          value={businessInfo.businessPhone}
          onChange={handleBusinessInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="+1 (555) 987-6543"
        />
      </div>
    </section>
  );
}

export default BusinessInfo;
