import React from 'react';

function ShippingInfo({ clientInfo, handleClientInfoChange }) {
  return (
    <section className="p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Shipping Information</h2>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Shipping Address</label>
        <input
          type="text"
          name="shippingAddress"
          value={clientInfo.shippingAddress}
          onChange={handleClientInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="123 Main St"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Shipping City</label>
        <input
          type="text"
          name="shippingCity"
          value={clientInfo.shippingCity}
          onChange={handleClientInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="Los Angeles"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Shipping ZIP</label>
        <input
          type="text"
          name="shippingZip"
          value={clientInfo.shippingZip}
          onChange={handleClientInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="90001"
        />
      </div>
    </section>
  );
}

export default ShippingInfo;
