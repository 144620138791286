import React, { useState, useEffect } from 'react';
import { templateOne, templateTwo, templateProfessional } from './pdfTemplates';
import LogoUploader from './LogoUploader';
import DownloadLimitAlert from './DownloadLimitAlert'; // Import the new alert component
import tmpl1 from '../images/tmpl_1.jpg'
import tmpl2 from '../images/tmpl_2.jpg'
import tmpl3 from '../images/tmpl_3.jpg'

function Footer({
  clientInfo,
  businessInfo,
  items,
  total,
  currency,
  t,
  taxRate,
  shippingCost,
  totalDiscount,
  advancePayment,
  invoiceNumber,
  invoiceDate,
  expirationDate,
  observations,
  terms,
}) {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState('templateOne'); // Default template
  const [logo, setLogo] = useState(null); // To store the uploaded logo
  const [canDownload, setCanDownload] = useState(false); // Track if the user can download

  // Function to check download limit
  const checkDownloadLimit = async () => {
    try {
      const response = await fetch('https://backend.invoicelypro.com/api/download-invoice', {
        method: 'POST',
        credentials: 'include', // Include cookies for non-logged-in users
      });

      if (response.status === 402) {
        setCanDownload(false); // User exceeded limit, disable the button
      } else {
        setCanDownload(true); // User can download
      }
    } catch (error) {
      setCanDownload(false);
    }
  };

  // Generate PDF based on selected template
  const generatePDF = () => {
    let doc;
    switch (selectedTemplate) {
      case 'templateTwo':
        doc = templateTwo({
          clientInfo,
          businessInfo,
          items,
          total,
          currency,
          t,
          taxRate,
          shippingCost,
          totalDiscount,
          advancePayment,
          invoiceNumber,
          invoiceDate,
          expirationDate,
          observations,
          terms,
        });
        break;
      case 'templateProfessional':
        doc = templateProfessional({
          clientInfo,
          businessInfo,
          items,
          total,
          currency,
          t,
          taxRate,
          shippingCost,
          totalDiscount,
          advancePayment,
          logo,
          invoiceNumber,
          invoiceDate,
          expirationDate,
          observations,
          terms,
          canDownload,
        });
        break;
      case 'templateOne':
      default:
        doc = templateOne({
          clientInfo,
          businessInfo,
          items,
          total,
          currency,
          t,
          taxRate,
          shippingCost,
          totalDiscount,
          advancePayment,
          invoiceNumber,
          invoiceDate,
          expirationDate,
          observations,
          terms,
        });
        break;
    }

    // Create Blob URL for PDF
    const pdfBlob = doc.output('blob');
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);

    // Update the PDF URL for preview
    setPdfUrl(pdfBlobUrl);
  };

  // Regenerate the PDF every time the invoice data changes
  useEffect(() => {
    generatePDF();
    // Clean up the previous URL to avoid memory leaks
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [
    clientInfo,
    businessInfo,
    items,
    total,
    currency,
    t,
    taxRate,
    shippingCost,
    totalDiscount,
    advancePayment,
    invoiceNumber,
    invoiceDate,
    expirationDate,
    observations,
    terms,
    selectedTemplate,
    logo,
    canDownload,
  ]);

  useEffect(() => {
    // Check the download limit on component mount
    checkDownloadLimit();
  }, []);
  
  // Template Images
  const templates = [
    { id: 'templateOne', name: 'Template One', imgSrc: tmpl1},
    { id: 'templateTwo', name: 'Template Two', imgSrc: tmpl2 },
    { id: 'templateProfessional', name: 'Template Professional', imgSrc: tmpl3 },
  ];

  return (
    <footer className="container mx-auto my-8 p-4 bg-white shadow-lg rounded-lg flex flex-col">

      {/* Logo Uploader */}
      <LogoUploader setLogo={setLogo} />

      {/* Template Selector as Horizontal Scrollable Section */}
      <div className="mb-4">
        <span className="text-lg font-semibold mb-3 mt-5 block">Select Template:</span>
        <div className="flex overflow-x-auto space-x-4 p-3 border rounded-lg">
          {templates.map((template) => (
            <div
              key={template.id}
              className={`flex-shrink-0 w-60 h-85 border-2 rounded-lg cursor-pointer ${
                selectedTemplate === template.id ? 'border-blue-500 scale-105' : 'border-gray-300'
              }`}
              onClick={() => setSelectedTemplate(template.id)}
            >
              <img
                src={template.imgSrc}
                alt={template.name}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Show Alert if download limit is reached */}
      {!canDownload && (
        <DownloadLimitAlert message={t('downloadLimitExceeded')} />
      )}

      {/* Invoice Preview Heading */}
      <h2 className="text-3xl font-semibold mb-4 pt-10">{t('invoicePreview')}</h2>

      {/* PDF Preview */}
      {pdfUrl && (
        <iframe
          id="iframe"
          src={pdfUrl}
          title={t('invoicePreview')}
          className="w-full h-svh border rounded-lg"
        />
      )}
    </footer>
  );
}

export default Footer;
