import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatCurrency, calculateSubtotal, calculateTotal } from "./Utils";

export const templateOne = ({
    clientInfo,
    businessInfo,
    items,
    total,
    currency,
    t,
    taxRate,
    shippingCost,
    totalDiscount,
    advancePayment,
}) => {
    const doc = new jsPDF("portrait", "pt", "a4");
    // Business Info
    doc.setFontSize(12);
    doc.text(businessInfo.businessName || t("yourBusiness"), 400, 40);
    doc.text(businessInfo.businessAddress || t("businessAddress"), 400, 60);
    doc.text(businessInfo.businessEmail || "business@example.com", 400, 80);
    doc.text(businessInfo.businessPhone || "(555) 987-6543", 400, 100);

    // Client Info
    doc.text(`${t("billTo")}:`, 40, 40);
    doc.text(clientInfo.name || t("clientName"), 40, 60);
    doc.text(clientInfo.clientAddress || t("clientAddress"), 40, 80);
    doc.text(clientInfo.email || "client@example.com", 40, 100);
    doc.text(clientInfo.phone || "(555) 123-4567", 40, 120);

    // Shipping Info
    doc.text(`${t("shippingAddress")}:`, 40, 160);
    doc.text(clientInfo.shippingAddress || "123 Main St", 40, 180);
    doc.text(`${clientInfo.shippingCity || "City"}, ${clientInfo.shippingZip || "ZIP"}`, 40, 200);

    // Handle Discount
    const hasDiscount = items.some((item) => item.discount > 0);

    // Define the table columns conditionally based on whether there is any discount
    const tableHeaders = hasDiscount
        ? [t("description"), t("quantity"), t("price"), t("discount"), t("total")]
        : [t("description"), t("quantity"), t("price"), t("total")];

    const tableBody = items.map((item) => [
        item.description || t("itemDescription"),
        item.quantity,
        formatCurrency(item.price * 1, currency, t),
        ...(hasDiscount ? [item.discount > 0 ? `${item.discount}%` : ""] : []),
        formatCurrency(
            item.discount > 0
                ? item.quantity * item.price * (1 - item.discount / 100)
                : item.quantity * item.price,
            currency,
            t
        ),
    ]);

    // Add table
    doc.autoTable({
        startY: 220,
        head: [tableHeaders],
        body: tableBody,
        theme: "striped",
        headStyles: { fillColor: [22, 160, 133] },
        margin: { left: 40, right: 40 },
    });

    // Calculate subtotal and total with taxes, shipping, and discount
    const subtotal = calculateSubtotal(items);
    const totalAfterAdjustments = calculateTotal(subtotal, totalDiscount, taxRate, shippingCost);

    // Add summary (subtotal, discount, tax, shipping, total)
    const finalY = doc.lastAutoTable.finalY || 240;
    doc.setFontSize(12);
    doc.text(`${t("subtotal")}: ${formatCurrency(subtotal, currency, t)}`, 400, finalY + 20);

    if (totalDiscount > 0) {
        doc.text(`${t("totalDiscount")} (${totalDiscount}%): ${formatCurrency(subtotal * (totalDiscount / 100), currency, t)}`, 400, finalY + 40);
    }

    if (taxRate > 0) {
        doc.text(`${t("taxes")} (${taxRate}%): ${formatCurrency(subtotal * (1 - totalDiscount / 100) * (taxRate / 100), currency, t)}`, 400, finalY + 60);
    }

    if (shippingCost > 0) {
        doc.text(`${t("shippingCost")}: ${formatCurrency(shippingCost, currency, t)}`, 400, finalY + 80);
    }

    // Show the advance payment and remaining balance
    if (advancePayment > 0) {
        doc.text(`${t("advancePayment")}: ${formatCurrency(advancePayment, currency, t)}`, 400, finalY + 100);
        doc.text(`${t("remainingBalance")}: ${formatCurrency(totalAfterAdjustments - advancePayment, currency, t)}`, 400, finalY + 120);
    } else {
        doc.text(`${t("total")}: ${formatCurrency(totalAfterAdjustments, currency, t)}`, 400, finalY + 100);
    }

    // addWatermark(doc);

    return doc;
};


export const templateTwo = ({
    clientInfo,
    businessInfo,
    items,
    total,
    currency,
    t,
    taxRate,
    shippingCost,
    totalDiscount,
    advancePayment,
}) => {
    const doc = new jsPDF("landscape", "pt", "a4"); // Different orientation
    // Business Info
    doc.setFontSize(12);
    doc.text(businessInfo.businessName || t("yourBusiness"), 500, 40);
    doc.text(businessInfo.businessAddress || t("businessAddress"), 500, 60);
    doc.text(businessInfo.businessEmail || "business@example.com", 500, 80);
    doc.text(businessInfo.businessPhone || "(555) 987-6543", 500, 100);

    // Client Info
    doc.text(`${t("billTo")}:`, 40, 40);
    doc.text(clientInfo.name || t("clientName"), 40, 60);
    doc.text(clientInfo.clientAddress || t("clientAddress"), 40, 80);
    doc.text(clientInfo.email || "client@example.com", 40, 100);
    doc.text(clientInfo.phone || "(555) 123-4567", 40, 120);

    // Shipping Info
    doc.text(`${t("shippingAddress")}:`, 40, 160);
    doc.text(clientInfo.shippingAddress || "123 Main St", 40, 180);
    doc.text(`${clientInfo.shippingCity || "City"}, ${clientInfo.shippingZip || "ZIP"}`, 40, 200);

    // Handle Discount
    const hasDiscount = items.some((item) => item.discount > 0);

    // Define the table columns conditionally based on whether there is any discount
    const tableHeaders = hasDiscount
        ? [t("description"), t("quantity"), t("price"), t("discount"), t("total")]
        : [t("description"), t("quantity"), t("price"), t("total")];

    const tableBody = items.map((item) => [
        item.description || t("itemDescription"),
        item.quantity,
        formatCurrency(item.price * 1, currency, t),
        ...(hasDiscount ? [item.discount > 0 ? `${item.discount}%` : ""] : []),
        formatCurrency(
            item.discount > 0
                ? item.quantity * item.price * (1 - item.discount / 100)
                : item.quantity * item.price,
            currency,
            t
        ),
    ]);

    // Add table
    doc.autoTable({
        startY: 220,
        head: [tableHeaders],
        body: tableBody,
        theme: "striped",
        headStyles: { fillColor: [22, 160, 133] },
        margin: { left: 40, right: 40 },
    });

    // Calculate subtotal and total with taxes, shipping, and discount
    const subtotal = calculateSubtotal(items);
    const totalAfterAdjustments = calculateTotal(subtotal, totalDiscount, taxRate, shippingCost);

    // Add summary (subtotal, discount, tax, shipping, total)
    const finalY = doc.lastAutoTable.finalY || 240;
    doc.setFontSize(12);
    doc.text(`${t("subtotal")}: ${formatCurrency(subtotal, currency, t)}`, 500, finalY + 20);

    if (totalDiscount > 0) {
        doc.text(`${t("totalDiscount")} (${totalDiscount}%): ${formatCurrency(subtotal * (totalDiscount / 100), currency, t)}`, 500, finalY + 40);
    }

    if (taxRate > 0) {
        doc.text(`${t("taxes")} (${taxRate}%): ${formatCurrency(subtotal * (1 - totalDiscount / 100) * (taxRate / 100), currency, t)}`, 500, finalY + 60);
    }

    if (shippingCost > 0) {
        doc.text(`${t("shippingCost")}: ${formatCurrency(shippingCost, currency, t)}`, 500, finalY + 80);
    }

    // Show the advance payment and remaining balance
    if (advancePayment > 0) {
        doc.text(`${t("advancePayment")}: ${formatCurrency(advancePayment, currency, t)}`, 500, finalY + 100);
        doc.text(`${t("remainingBalance")}: ${formatCurrency(totalAfterAdjustments - advancePayment, currency, t)}`, 500, finalY + 120);
    } else {
        doc.text(`${t("total")}: ${formatCurrency(totalAfterAdjustments, currency, t)}`, 500, finalY + 100);
    }

    // addWatermark(doc);

    return doc;
};


// New Professional Template
export const templateProfessional = ({
    clientInfo,
    businessInfo,
    items,
    total,
    currency,
    t,
    taxRate,
    shippingCost,
    totalDiscount,
    advancePayment,
    logo,
    invoiceNumber,
    invoiceDate,
    expirationDate,
    observations,
    terms,
    canDownload
}) => {
    const doc = new jsPDF("portrait", "pt", "a4");

    // Professional Header Background Color
    doc.setFillColor(22, 160, 133); // A nice teal background
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), 66, "F"); // Full-width rectangle for the header
    let logoXPosition;
    logoXPosition = 450
    // Add Business Logo if available
    if (logo) {
        const pageWidth = doc.internal.pageSize.getWidth();
        const maxSize = 100; // The size for the square container

        // Get image properties using jsPDF's built-in method
        const imgProps = doc.getImageProperties(logo);
        const imgWidth = imgProps.width;
        const imgHeight = imgProps.height;

        // Calculate aspect ratio
        const aspectRatio = imgWidth / imgHeight;
        let logoWidth, logoHeight;

        if (aspectRatio > 1) {
            logoWidth = maxSize;
            logoHeight = maxSize / aspectRatio;
        } else {
            logoHeight = maxSize;
            logoWidth = maxSize * aspectRatio;
        }

        // Add the image to the PDF (top-right corner)
        logoXPosition = pageWidth - logoWidth - 40; // X position for the logo
        const logoYPosition = 20; // Y position for the logo

        doc.addImage(logo, "PNG", logoXPosition, logoYPosition, logoWidth, logoHeight);
    }
    const invoiceInfoXPosition = logoXPosition - 30;

    // Set style for Invoice Number label
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.setTextColor(91, 82, 80); // Teal color for the label
    doc.text(`${t("invoiceNumber")}:`, invoiceInfoXPosition, 140);

    // Set style for Invoice Number value
    const invoiceNumberXPosition = doc.getTextWidth(`${t("invoiceNumber")}:`) + invoiceInfoXPosition + 2;
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0); // Black color for the value
    doc.text(`${invoiceNumber}`, invoiceNumberXPosition, 140);

    // Set style for Invoice Date label
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(91, 82, 80); // Teal color for the label
    doc.text(`${t("invoiceDate")}:`, invoiceInfoXPosition, 160);

    // Set style for Invoice Date value
    const invoiceDateXPosition = doc.getTextWidth(`${t("invoiceDate")}:`) + invoiceInfoXPosition + 2;
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0); // Black color for the value
    doc.text(`${invoiceDate}`, invoiceDateXPosition, 160);

    // Set style for Expiration Date label
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(91, 82, 80); // Teal color for the label
    doc.text(`${t("expirationDate")}:`, invoiceInfoXPosition, 180);

    // Set style for Expiration Date value
    const expirationDateXPosition = doc.getTextWidth(`${t("expirationDate")}:`) + invoiceInfoXPosition + 2;
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0); // Black color for the value
    doc.text(`${expirationDate}`, expirationDateXPosition, 180);

    // Business Info on top
    doc.setFontSize(20);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(255, 255, 255); // White text for the header
    doc.text(businessInfo.businessName || t("yourBusiness"), 40, 40);
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);
    doc.text(businessInfo.businessAddress || t("businessAddress"), 40, 60);
    doc.setTextColor(0, 0, 0);
    doc.text(businessInfo.businessEmail || "business@example.com", 40, 80);
    doc.text(businessInfo.businessPhone || "(555) 987-6543", 40, 100);

    // Client Info Section
    doc.setFontSize(14);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(91, 82, 80);

    doc.text(`${t("billTo")}:`, 40, 140);
    doc.setTextColor(0, 0, 0); // Back to black text
    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal");
    doc.text(clientInfo.name || t("clientName"), 40, 160);
    doc.text(clientInfo.clientAddress || t("clientAddress"), 40, 180);
    doc.text(clientInfo.email || "client@example.com", 40, 200);
    doc.text(clientInfo.phone || "(555) 123-4567", 40, 220);

    // Shipping Info Section
    doc.setFontSize(14);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(91, 82, 80);
    doc.text(`${t("shippingAddress")}:`, 40, 260);
    doc.setTextColor(0, 0, 0); // Back to black text
    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal");
    doc.text(clientInfo.shippingAddress || "123 Main St", 40, 280);
    doc.text(`${clientInfo.shippingCity || "City"}, ${clientInfo.shippingZip || "ZIP"}`, 40, 300);

    // Table with Items
    const hasDiscount = items.some((item) => item.discount > 0);
    const tableHeaders = hasDiscount
        ? [t("description"), t("quantity"), t("price"), t("discount"), t("total")]
        : [t("description"), t("quantity"), t("price"), t("total")];

    const tableBody = items.map((item) => [
        item.description || t("itemDescription"),
        item.quantity,
        formatCurrency(item.price * 1, currency, t),
        ...(hasDiscount ? [item.discount > 0 ? `${item.discount}%` : ""] : []),
        formatCurrency(
            item.discount > 0
                ? item.quantity * item.price * (1 - item.discount / 100)
                : item.quantity * item.price,
            currency,
            t
        ),
    ]);

    // Adding the table with alternating row colors
    doc.autoTable({
        startY: 330,
        head: [tableHeaders],
        body: tableBody,
        theme: "grid",
        headStyles: { fillColor: [22, 160, 133] }, // Blue background for header
        alternateRowStyles: { fillColor: [239, 239, 239] }, // Light grey for alternating rows
        margin: { left: 40, right: 40 },
    });

    // Calculate subtotal and total with taxes, shipping, and discount
    const subtotal = calculateSubtotal(items);
    const totalAfterAdjustments = calculateTotal(subtotal, totalDiscount, taxRate, shippingCost);

    // Summary section (subtotal, discount, taxes, shipping, total)


    const finalY = doc.lastAutoTable.finalY || 440;
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.setTextColor(91, 82, 80); // Teal color for labels
    doc.text(`${t("subtotal")}:`, 400, finalY + 30);

    // Set style for subtotal value
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0, 0, 0); // Black color for the value
    doc.text(`${formatCurrency(subtotal, currency, t)}`, 500, finalY + 30);

    let actualY = finalY + 50;

    if (totalDiscount > 0) {
        doc.setFont("Helvetica", "bold");
        doc.setTextColor(91, 82, 80); // Teal color for labels
        doc.text(`${t("totalDiscount")} (${totalDiscount}%):`, 400, actualY);

        doc.setFont("Helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Black color for the value
        doc.text(`${formatCurrency(subtotal * (totalDiscount / 100), currency, t)}`, 500, actualY);

        actualY += 25;
    }

    if (taxRate > 0) {
        doc.setFont("Helvetica", "bold");
        doc.setTextColor(91, 82, 80); // Teal color for labels
        doc.text(`${t("taxes")} (${taxRate}%):`, 400, actualY);

        doc.setFont("Helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Black color for the value
        doc.text(`${formatCurrency(subtotal * (1 - totalDiscount / 100) * (taxRate / 100), currency, t)}`, 500, actualY);

        actualY += 25;
    }

    if (shippingCost > 0) {
        doc.setFont("Helvetica", "bold");
        doc.setTextColor(91, 82, 80); // Teal color for labels
        doc.text(`${t("shippingCost")}:`, 400, actualY);

        doc.setFont("Helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Black color for the value
        doc.text(`${formatCurrency(shippingCost, currency, t)}`, 500, actualY);

        actualY += 25;
    }

    // Show the advance payment and remaining balance
    if (advancePayment > 0) {
        doc.setFont("Helvetica", "bold");
        doc.setTextColor(91, 82, 80); // Teal color for labels
        doc.text(`${t("advancePayment")}:`, 400, actualY);

        doc.setFont("Helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Black color for the value
        doc.text(`${formatCurrency(advancePayment, currency, t)}`, 500, actualY);

        actualY += 25;

        doc.setFont("Helvetica", "bold");
        doc.setTextColor(91, 82, 80); // Teal color for labels
        doc.text(`${t("remainingBalance")}:`, 400, actualY);

        doc.setFont("Helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Black color for the value
        doc.text(`${formatCurrency(totalAfterAdjustments - advancePayment, currency, t)}`, 500, actualY);

        actualY += 50;
    } else {
        doc.setFont("Helvetica", "bold");
        doc.setTextColor(91, 82, 80); // Teal color for labels
        doc.text(`${t("total")}:`, 400, actualY);

        doc.setFont("Helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Black color for the value
        doc.text(`${formatCurrency(totalAfterAdjustments, currency, t)}`, 500, actualY);

        actualY += 50;
    }

    // Observations and Terms at the bottom with translation
    const maxLineWidth = 400;
    // Check if 'observations' is provided
    if (observations && observations.trim() !== "") {
        doc.setFont("Helvetica", "bold");
        doc.setTextColor(91, 82, 80); // Teal color for labels
        actualY += 20; // Add some space before observations label
        doc.text(`${t("observations")}:`, 40, actualY);

        // Set style for the observations text
        doc.setFont("Helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Black color for the value

        // Split observations text into multiple lines
        // Set a max width for each line of text
        const observationsLines = doc.splitTextToSize(observations, maxLineWidth);

        // Calculate the height based on the number of lines (each line is approximately 12pt in height)
        const observationsHeight = observationsLines.length * 12;

        // Render the observations text
        doc.text(observationsLines, 110, actualY + 20); // 140 is the X position for the text

        // Move Y position down by the height of the observations block
        actualY += observationsHeight + 40; // Add some padding after observations
    }

    // Check if 'terms' is provided
    if (terms && terms.trim() !== "") {
        doc.setFont("Helvetica", "bold");
        doc.setTextColor(91, 82, 80); // Teal color for labels
        doc.text(`${t("terms")}:`, 40, actualY);

        // Set style for the terms text
        doc.setFont("Helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Black color for the value

        // Split terms text into multiple lines
        const termsLines = doc.splitTextToSize(terms, maxLineWidth);

        // Calculate the height based on the number of lines
        const termsHeight = termsLines.length * 12;

        // Render the terms text
        doc.text(termsLines, 110, actualY + 20);

        // Move Y position down by the height of the terms block
        actualY += termsHeight + 30; // Add some padding after terms
    }

    if (!canDownload) {
        addWatermark(doc);
    }

    return doc;
};

const addWatermark = (doc) => {

    doc.setFillColor(22, 160, 133, 250); // A nice teal background
    doc.rect(0, 180, doc.internal.pageSize.getWidth(), 29, "F"); // Full-width rectangle for the header

    doc.setFontSize(15);
    doc.setTextColor(150, 150, 150);
    doc.text('Visit WWW.INVOICELYPRO.COM to get more templates like this!', 70, 200);
    doc.setFontSize(90);
    
    doc.text('InvoicelyPro', 150, 640 , {
      angle: 50,
    });

    doc.setFillColor(22, 160, 133, 250); // A nice teal background
    doc.rect(0, 680, doc.internal.pageSize.getWidth(), 29, "F"); // Full-width rectangle for the header
    doc.setFontSize(15);
    doc.text('Visit WWW.INVOICELYPRO.COM to get more templates like this!', 70, 700);
  };
