import React from 'react';

function InvoiceItems({ items, addItem, removeItem, handleItemChange, toggleDiscount }) {
  // Manejar el cambio de los inputs, restringiendo longitud de caracteres
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;

    // Permitir solo hasta 12 caracteres para los inputs de cantidad y precio
    if ((name === 'quantity' && value.length > 9) || (name === 'price' && value.length > 12)) {
      return;
    }

    handleItemChange(index, e);
  };

  return (
    <section className="container mx-auto my-8 p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Invoice Items</h2>
      {items.map((item, index) => (
        <div key={index} className={`grid grid-cols-1 md:grid-cols-6 gap-4 mb-4`}>
          <div>
            <label className="block text-sm font-medium text-gray-700 pt-4">Description</label>
            <input
              type="text"
              name="description"
              value={item.description}
              onChange={(e) => handleInputChange(index, e)}
              className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
              placeholder="Item description"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 pt-4">Quantity</label>
            <input
              type="number"
              name="quantity"
              value={item.quantity}
              onChange={(e) => handleInputChange(index, e)}
              className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
              placeholder="1"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 pt-4">Price</label>
            <input
              type="number"
              name="price"
              value={item.price}
              onChange={(e) => handleInputChange(index, e)}
              className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
              placeholder="0.00"
            />
          </div>

          {/* Campo de descuento condicional */}
          {item.showDiscount && (
            <div>
              <label className="block text-sm font-medium text-gray-700 pt-4">Discount (%)</label>
              <input
                type="number"
                name="discount"
                value={item.discount}
                onChange={(e) => handleItemChange(index, e)}
                className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
                placeholder="0"
                max={100}
                min={0}
              />
            </div>
          )}

          {/* Mostrar el botón para activar/desactivar el descuento */}
          <div className="flex items-end">
            <button
              className={`py-2 px-4 rounded ${item.showDiscount ? 'bg-yellow-500' : 'bg-blue-500'} text-white`}
              onClick={() => toggleDiscount(index)}
            >
              {item.showDiscount ? 'Hide Discount' : 'Add Discount'}
            </button>
          </div>

          <div className="flex items-end">
            <button
              className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
              onClick={() => removeItem(index)}
            >
              Remove
            </button>
          </div>
        </div>
      ))}
      <button className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded" onClick={addItem}>
        Add Item
      </button>
    </section>
  );
}

export default InvoiceItems;
