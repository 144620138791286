import React, { useState } from 'react';

function LogoUploader({ setLogo }) {
  const [logoPreview, setLogoPreview] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleLogoUpload = (e) => {
    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0]; // Handle file drop
    } else {
      file = e.target.files[0]; // Handle file upload via input
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result); // Set the logo as a base64 string
        setLogoPreview(reader.result); // Show a preview of the logo
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleLogoUpload(e);
  };

  return (
    <div className="container mx-auto my-4 p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Upload Your Business Logo</h2>
      <div
        className={`border-2 border-dashed p-2 rounded-lg ${isDragging ? 'border-blue-500 bg-blue-100' : 'border-gray-400 bg-gray-100'}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          accept="image/*"
          className="hidden"
          id="logo-upload"
          onChange={handleLogoUpload}
        />
        <label htmlFor="logo-upload" className="flex flex-col items-center justify-center cursor-pointer">
          <span className="text-lg m-6">Drag and drop your logo here, or click to select a file</span>
        </label>
      </div>

      {logoPreview && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Logo Preview:</h3>
          <img src={logoPreview} alt="Business Logo" className="mt-2 w-32 h-32 object-contain border border-gray-300 rounded-lg" />
        </div>
      )}
    </div>
  );
}

export default LogoUploader;
