import React, { useState } from "react";
import Header from "./components/Header";
import ClientInfo from "./components/ClientInfo";
import ShippingInfo from "./components/ShippingInfo";
import BusinessInfo from "./components/BusinessInfo";
import InvoiceItems from "./components/InvoiceItems";
import InvoiceInformation from "./components/InvoiceInformation";
import InvoiceSettings from "./components/InvoiceSettings";
import InvoiceAdjustments from "./components/InvoiceAdjustments";
import Footer from "./components/Footer";
import Profile from "./components/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  // State for client information
  const [clientInfo, setClientInfo] = useState({
    name: "",
    email: "",
    phone: "",
    clientAddress: "",
    shippingAddress: "",
    shippingCity: "",
    shippingZip: "",
  });

  // State for business information
  const [businessInfo, setBusinessInfo] = useState({
    businessName: "",
    businessAddress: "",
    businessEmail: "",
    businessPhone: "",
  });

  const [taxRate, setTaxRate] = useState(0); // Default tax rate in percentage
  const [shippingCost, setShippingCost] = useState(0); // Default shipping cost
  const [totalDiscount, setTotalDiscount] = useState(0); // Default total discount in percentage
  const [advancePayment, setAdvancePayment] = useState(0); // New state for advance payment

  const handleTaxChange = (e) => { setTaxRate(Number(e.target.value)); };
  const handleShippingChange = (e) => { setShippingCost(Number(e.target.value)); };
  const handleDiscountChange = (e) => { setTotalDiscount(Number(e.target.value)); };
  const handleAdvanceChange = (e) => setAdvancePayment(Number(e.target.value)); // New handler for advance payment


  // Invoice Information
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [observations, setObservations] = useState('');
  const [terms, setTerms] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'invoiceNumber') setInvoiceNumber(value);
    else if (name === 'invoiceDate') setInvoiceDate(value);
    else if (name === 'expirationDate') setExpirationDate(value);
    else if (name === 'observations') setObservations(value);
    else if (name === 'terms') setTerms(value);
  };

  // State for invoice items
  const [items, setItems] = useState([
    {
      description: "",
      quantity: 1,
      price: 0,
      discount: 0,
      showDiscount: false,
    },
  ]);

  const [currency, setCurrency] = useState("USD"); // Default currency
  const [language, setLanguage] = useState("en"); // Default language

  // Function to handle currency change
  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  // Function to handle language change
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  // Example translations (expand as needed)
  const translations = {
    en: {
      yourBusiness: "Your Business",
      businessAddress: "Business Address",
      clientName: "Customer Name",
      clientAddress: "Customer Address",
      billTo: "Bill To",
      shippingAddress: "Shipping Address",
      description: "Description",
      quantity: "Quantity",
      price: "Price",
      discount: "Discount (%)",
      total: "Total",
      generatePDF: "Generate PDF",
      invoicePreview: "Invoice Preview",
      itemDescription: "Item description",
      subtotal: 'Subtotal',
      taxes: 'Taxes',
      shippingCost: 'Shipping Cost',
      totalDiscount: 'Discount',
      advancePayment: 'Advanced Payment',
      remainingBalance: 'Remaining Balance',
      downloadLimitExceeded: 'You have reached your daily limit. Please log in to make a payment.',
      locale: "en-US", // Locale for formatting
    },
    es: {
      yourBusiness: "Su Negocio",
      businessAddress: "Dirección Comercial",
      clientName: "Nombre Cliente",
      clientAddress: "Dirección Cliente",
      billTo: "Facturar a",
      shippingAddress: "Dirección de Envío",
      description: "Descripción",
      quantity: "Cantidad",
      price: "Precio",
      discount: "Descuento (%)",
      total: "Total",
      generatePDF: "Generar PDF",
      invoicePreview: "Vista previa de la factura",
      itemDescription: "Descripción del artículo",
      subtotal: 'Subtotal',
      taxes: 'Impuestos',
      shippingCost: 'Costes de Envío',
      totalDiscount: 'Descuento',
      advancePayment: 'Pago Adelantado',
      remainingBalance: 'Saldo Restante',
      locale: "es-ES", // Locale for formatting
    },
    // Add more languages if needed
  };

  // Function to translate based on the selected language
  const t = (key) => translations[language][key];

  // State for total
  const [total, setTotal] = useState(0);

  // Handle client info change
  const handleClientInfoChange = (e) => {
    const { name, value } = e.target;
    setClientInfo({
      ...clientInfo,
      [name]: value,
    });
  };

  // Handle business info change
  const handleBusinessInfoChange = (e) => {
    const { name, value } = e.target;
    setBusinessInfo({
      ...businessInfo,
      [name]: value,
    });
  };

  // Handle adding a new item
  const addItem = () => {
    setItems([...items, { description: "", quantity: 1, price: 0 }]);
  };

  // Handle removing an item
  const removeItem = (index) => {
    const newItems = items.filter((item, i) => i !== index);
    setItems(newItems);
    calculateTotal(newItems);
  };

  // Handle item change (description, quantity, price)
  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const newItems = items.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setItems(newItems);
    calculateTotal(newItems);
  };

  // Calculate total for all items
  const calculateTotal = (items) => {
    const newTotal = items.reduce((acc, item) => {
      const itemTotal =
        item.discount > 0
          ? item.quantity * item.price * (1 - item.discount / 100)
          : item.quantity * item.price;
      return acc + itemTotal;
    }, 0);
    setTotal(Math.round(newTotal * 100) / 100);
  };

  const toggleDiscount = (index) => {
    const updatedItems = [...items];
    updatedItems[index].showDiscount = !updatedItems[index].showDiscount;
    updatedItems[index].discount = 0;
    setItems(updatedItems);
  };

  const { isAuthenticated, isLoading, user, loginWithRedirect, logout } = useAuth0(); // Get Auth0 values

  if (isLoading) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-100 ">
      <Analytics/>
      <SpeedInsights/>
      <Header
        isAuthenticated={isAuthenticated}
        user={user}
        loginWithRedirect={loginWithRedirect}
        logout={logout}
      />
      {isLoading && (
        <div className="mt-8">
          Please log in to manage your invoices and access additional features.
        </div>
      )}

      {isAuthenticated ? (
        <div className="mt-8">
          <Profile />
          {/* Add your authenticated content (like invoice management) here */}
        </div>
      ) : (
        <p className="text-center mt-8">
          Please log in to manage your invoices and access additional features.
        </p>
      )}

      {/* Display the three sections side by side using Tailwind CSS grid */}
      <div className="w-auto m-20">
        <InvoiceSettings
          currency={currency}
          language={language}
          handleCurrencyChange={handleCurrencyChange}
          handleLanguageChange={handleLanguageChange}
        />
        {/* Invoice Information Section */}
        <InvoiceInformation
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          expirationDate={expirationDate}
          observations={observations}
          terms={terms}
          handleInputChange={handleInputChange}
        />

        <div className="container mx-auto my-8 grid grid-cols-1 md:grid-cols-3 gap-8">
          <ClientInfo
            clientInfo={clientInfo}
            handleClientInfoChange={handleClientInfoChange}
          />
          <ShippingInfo
            clientInfo={clientInfo}
            handleClientInfoChange={handleClientInfoChange}
          />
          <BusinessInfo
            businessInfo={businessInfo}
            handleBusinessInfoChange={handleBusinessInfoChange}
          />
        </div>

        {/* Invoice items, preview, and footer */}
        <InvoiceItems
          items={items}
          addItem={addItem}
          removeItem={removeItem}
          handleItemChange={handleItemChange}
          toggleDiscount={toggleDiscount}
        />
        {/* <InvoicePreview clientInfo={clientInfo} businessInfo={businessInfo} items={items} total={total} /> */}

        <InvoiceAdjustments
          taxRate={taxRate}
          shippingCost={shippingCost}
          totalDiscount={totalDiscount}
          advancePayment={advancePayment}  // New state for advance payment
          handleTaxChange={handleTaxChange}
          handleShippingChange={handleShippingChange}
          handleDiscountChange={handleDiscountChange}
          handleAdvanceChange={handleAdvanceChange}  // New handler for advance payment
        />

        <Footer
          clientInfo={clientInfo}
          businessInfo={businessInfo}
          items={items}
          total={total}
          currency={currency}
          totalDiscount={totalDiscount}
          taxRate={taxRate}
          shippingCost={shippingCost}
          advancePayment={advancePayment}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          expirationDate={expirationDate}
          observations={observations}
          terms={terms}
          t={t}
        />
      </div>
    </div>
  );
}

export default App;
