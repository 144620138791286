import React from 'react';

function InvoiceAdjustments({ taxRate, shippingCost, totalDiscount, advancePayment, handleTaxChange, handleShippingChange, handleDiscountChange, handleAdvanceChange }) {
  return (
    <section className="container mx-auto my-8 p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Invoice Adjustments</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
        {/* Tax Rate Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 pt-4">Tax Rate (%)</label>
          <input
            type="number"
            name="taxRate"
            value={taxRate}
            onChange={handleTaxChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
            placeholder="0"
            min={0}
            max={100}
          />
        </div>

        {/* Shipping Cost Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 pt-4">Shipping Cost</label>
          <input
            type="number"
            name="shippingCost"
            value={shippingCost}
            onChange={handleShippingChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
            placeholder="0.00"
            min={0}
          />
        </div>

        {/* Total Discount Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 pt-4">Total Discount (%)</label>
          <input
            type="number"
            name="totalDiscount"
            value={totalDiscount}
            onChange={handleDiscountChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
            placeholder="0"
            min={0}
            max={100}
          />
        </div>

        {/* Advance Payment Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 pt-4">Advance Payment</label>
          <input
            type="number"
            name="advancePayment"
            value={advancePayment}
            onChange={handleAdvanceChange}
            className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
            placeholder="0.00"
            min={0}
          />
        </div>
      </div>
    </section>
  );
}

export default InvoiceAdjustments;
