// Helper functions
export const formatCurrency = (value, currency, t) => {
    return new Intl.NumberFormat(t("locale"), {
        style: "currency",
        currency: currency,
        currencyDisplay: "symbol", // Ensures that the currency symbol is shown
    }).format(value);
};

export const calculateSubtotal = (items) => {
    return items.reduce((acc, item) => {
        const itemTotal =
            item.quantity * item.price * (1 - (item.discount || 0) / 100);
        return acc + itemTotal;
    }, 0);
};

export const calculateTotal = (subtotal, totalDiscount, taxRate, shippingCost) => {
    const discountAmount = subtotal * (totalDiscount / 100);
    const taxedAmount = (subtotal - discountAmount) * (1 + taxRate / 100);
    return taxedAmount + shippingCost;
};
