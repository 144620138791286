import React from 'react';

function ClientInfo({ clientInfo, handleClientInfoChange }) {
  return (
    <section className="p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Client Information</h2>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Client Name</label>
        <input
          type="text"
          name="name"
          value={clientInfo.name}
          onChange={handleClientInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="John Doe"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Client Email</label>
        <input
          type="email"
          name="email"
          value={clientInfo.email}
          onChange={handleClientInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="client@example.com"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Client Phone</label>
        <input
          type="text"
          name="phone"
          value={clientInfo.phone}
          onChange={handleClientInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="+1 (555) 123-4567"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 pt-4">Client Address</label>
        <input
          type="text"
          name="address"
          value={clientInfo.clientAddress}
          onChange={handleClientInfoChange}
          className="mt-2 block w-full rounded-lg border-2 border-gray-400 shadow-md focus:ring-blue-400 focus:border-blue-400 sm:text-base p-2"
          placeholder="456 Client St, Apt 789"
        />
      </div>
    </section>
  );
}

export default ClientInfo;
