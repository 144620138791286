import React from 'react';

function Header({ isAuthenticated, user, loginWithRedirect, logout }) {
  return (
    <header className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">Invoice Generator</h1>

        <div>
          {/* If user is authenticated, display their name and logout button */}
          {isAuthenticated ? (
            <div>
              <span className="mr-4">Welcome, {user.name}!</span>
              <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
              >
                Logout
              </button>
            </div>
          ) : (
            <>
              <button
                onClick={loginWithRedirect}
                className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
              >
                Login
              </button>
              <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
              >
                Logout
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
